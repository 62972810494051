import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';

import { resolveId, getPostCover } from '@wix/communities-blog-client-common';

import { connect } from '../../../common/components/runtime-context';
import withFontClassName from '../../../common/hoc/with-font-class-name';
import RecentPostsListItemWithCover from './recent-posts-list-item-with-cover';
import RecentPostsListItemWithoutCover from './recent-posts-list-item-without-cover';
import styles from './recent-posts-list-item.scss';

export const RecentPostsListItem = (props) => {
  const { post, incrementPostLikeCount, isMetadataFooterVisible } = props;
  const onLikeClick = () => incrementPostLikeCount(resolveId(post));
  const {
    shouldRender: withCover,
    imageMetadata,
    videoMetadata,
  } = getPostCover(post);
  const postLink = `/${post.slug}`;
  const Component = withCover
    ? RecentPostsListItemWithCover
    : RecentPostsListItemWithoutCover;
  const componentProps = {
    postLink,
    type: 'recentPosts',
    onLikeClick,
    imageMetadata,
    videoMetadata,
    ...props,
  };

  const articleStyle = {
    paddingTop: `calc(56.25% + ${isMetadataFooterVisible ? '148px' : '103px'})`,
  };

  return (
    <article
      className={styles.article}
      tabIndex="0"
      data-hook="recent-post-list-item"
      style={articleStyle}
    >
      <div className={styles.container}>
        <Component {...componentProps} />
      </div>
    </article>
  );
};

RecentPostsListItem.propTypes = {
  post: PropTypes.object.isRequired,
  incrementPostLikeCount: PropTypes.func.isRequired,
  isMetadataFooterVisible: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  incrementPostLikeCount: actions.incrementPostLikeCount,
});

// prettier-ignore
export default flowRight(
  withFontClassName,
  connect(mapRuntimeToProps),
)(RecentPostsListItem);
